@import '../../../../../styles/shared';

.label {
  border-radius: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  @include margin-left(5px);
  @include padding(2px, 5px, 1px, 5px);
  position: relative;
  top: -1px;
  text-transform: uppercase;

  @include theme-only($DEFAULT) {
    text-transform: none;
  }
}
